import React from "react";
import "./about.scss";

function About() {
  const viewCV = () => {
    window.open("Ashley_Littlewood_CV_NP.pdf", "_blank", "fullscreen=yes");
  };

  return (
    <div className="about" id="about">
        <div className="about-container">
          <div className="imgContainer">
            <img src="assets/profile.png" alt="" />
          </div>
          <div className="wrapper">
            <h1>About Me</h1>
            <p>
              Hi 🙋‍♂️, I'm Ashley a Software Developer from the UK specialising in C#, .NET and React. 
              I currently develop applciations for the sports betting industry.
              Outside of work you can typically find me taking photos 📷 , travelling ✈️ , or hiking 🥾. 
              Please take a look at my portfolio by scrolling down the webpage 🙂.
            </p>
            <div className="cv-button-wrapper">
              <div className="cv-button">
                <button onClick={viewCV} class="view-button cv-button">
                  View CV
                  <div class="icon">
                    <i class="fa-solid fa-file-lines"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default About;
